export default function Button({ text, buttonType, onClick }) {
  return (
    <>
      {buttonType === "defaultButton" && (
        <button
          className="w-[100%] font-sans font-bold text-[26px] text-white bg-[#6fd799] p-2 rounded-lg shadow-xl drop-shadow-lg"
          onClick={onClick}
        >
          {text}
        </button>
      )}
      {buttonType === "listButton" && (
        <button
          className="w-[100px] font-sans font-bold text-[14px] text-white bg-[#6fd799] p-1 rounded-lg shadow-xl drop-shadow-lg"
          onClick={onClick}
        >
          {text}
        </button>
      )}
      {buttonType === "disabledButton" && (
        <button
          className="w-[100px] font-sans font-bold text-[14px] text-white bg-[#d6f0d1] p-1 rounded-lg shadow-xl drop-shadow-lg"
          disabled
        >
          {text}
        </button>
      )}
      {buttonType === "availableBtn" && (
        <button className="w-[100%] cursor-default font-sans font-bold text-[14px] text-white bg-[#028737] py-[6px] px-[20px] rounded-[50px] shadow-xl drop-shadow-lg">
          Disponível
        </button>
      )}
      {buttonType === "onMaintenanceBtn" && (
        <button className="w-[100%] cursor-default font-sans font-bold text-[14px] text-white bg-[#6F0007] py-[6px] px-[20px] rounded-[50px] shadow-xl drop-shadow-lg">
          Em Manutenção
        </button>
      )}
      {buttonType === "inProductionBtn" && (
        <button className="w-[100%] cursor-default font-sans font-bold text-[14px] text-white bg-[#004AA1] py-[6px] px-[20px] rounded-[50px] shadow-xl drop-shadow-lg">
          Em Produção
        </button>
      )}
    </>
  );
}