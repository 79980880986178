import React from "react";

export default function dashboardPreview({ title, image, url }) {
  const handleClick = () =>{
    window.open(url, '_blank')
  }

  return (
    <div className="flex flex-col items-center">
      <button 
        className="bg-[#042b0b] text-center font-bold text-[20px] mb-4 mt-6 text-white p-3 rounded-3xl"
        onClick={handleClick}
      >
        {title}
      </button>
      <img src={image} alt='Preview Dashboard' className="mt-4" />
    </div>
  );
}