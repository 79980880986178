import React, { useState } from "react";
import Button from "../Button";
import SidebarInfoHome from "../sidebarInfoHome/SidebarInfoHome";

export default function SecondaryCard({ hasListTitle, hasTitle, dataList }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const selectElement = (item) => {
    setSelectedItem(item);
  };

  const clearSelectedItem = () => {
    setSelectedItem(null);
  };

  return (
    <section className="text-white text-[20px] font-semibold mt-[64px] relative">
      {hasListTitle && (
        <>
          <ul className="flex justify-between items-center">
            <li className="w-3/5 text-16 text-black font-semibold">Maiores Alterações</li>
            <li className="w-1/5 text-16 text-black font-semibold text-center">Data</li>
            <li className="w-1/5"></li>
          </ul>
          <div className="my-[10px] border-t-2 border-[#8B8B8B]"></div>
        </>
      )}
      <div className="bg-[#042b0b] rounded-2xl p-[10px] flex flex-col gap-4">
        {hasTitle && (
          <div className="flex gap-2 items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/Icons/InfoIconWhite.png`} alt="Ícone representando as últimas novidades" />
            <h2>Últimas Novidades</h2>
          </div>
        )}
        {dataList?.map((el, index) => (
          <div key={index} className="flex items-center">
            <span className="w-3/5">{el.Novidade}</span>
            <span className="w-1/5 text-center">{new Date(el.Data).toLocaleDateString()}</span>
            <div className="w-1/5 text-right">
              <Button text="Acessar" buttonType="listButton" onClick={() => selectElement(el)} />
            </div>
          </div>
        ))}
      </div>
      {selectedItem && (
        <SidebarInfoHome
          isOpen={true}
          toggleSidebar={clearSelectedItem}
          title={selectedItem.Novidade}
          content={selectedItem.Descrição}
        />
      )}
    </section>
  );
}