import { useState, useEffect } from "react";
import Sidebar from "../layout/sidebar/Sidebar";
import Button from "../components/Button";
import CustomIframe from "../components/CustomIframe";
import Modal from "../components/Modal";
import Input from "../components/Input";

export default function RelatoriosPage() {
  const [relatorios, setRelatorios] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');

  const itemsPerPage = 7;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  useEffect(() => {
    const buscarRelatoriosDoNotion = async () => {
      try {
        const response = await fetch(
          "https://script.googleusercontent.com/a/macros/mediamonks.com/echo?user_content_key=bwu1gTtYomXzbutLOBmF1dQLBgNBcpEiIFB7Kc7KlmdeTrr68H-M21wAyfy6DpaTXTQz7hmxObRIfdkWw0kk77quGlauEc2bOJmA1Yb3SEsKFZqtv3DaNYcMrmhZHmUMi80zadyHLKBAuzcrGt-rN8AyoApjNwUbaAD8aA7uc1cX11QH8lLJ9H7G5wu0X4i28DxbasdJpk1kH12UepH2JE6M2vA9-t_NyWBgvsdJfc-6fbNFehneP50VVsEh1WIZHdfc-lsnva7c_ScPTHfLrg&lib=MjIGlqIOn2649YDm8fh2-LlfNaLzcBGAC" // Substitua pela URL do seu Apps Script
        );

        if (!response.ok) {
          throw new Error(`Erro HTTP! status: ${response.status}`);
        }

        const data = await response.json();
        setRelatorios(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar dados do Notion:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    buscarRelatoriosDoNotion();
  }, []);

  
  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsOpen(true);
  };
  
  const getUniqueValues = (field) => {
    const uniqueValues = relatorios.map((el) => el[field]);
    return [...new Set(uniqueValues)];
  };

  const renderItems = () => {
    // Filtrar relatórios com base no searchTerm, selectedType e selectedBrand
    const filteredRelatorios = relatorios.filter((el) => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearchTerm = (
        el.name.toLowerCase().includes(searchLower) || // Filtra pelo nome
        el.brand.toLowerCase().includes(searchLower) || // Filtra pela marca
        el.type.toLowerCase().includes(searchLower) // Filtra pelo tipo
      );
      const matchesType = selectedType ? el.type === selectedType : true;
      const matchesBrand = selectedBrand ? el.brand === selectedBrand : true;

      return matchesSearchTerm && matchesType && matchesBrand;
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredRelatorios.length);
    return filteredRelatorios.slice(startIndex, endIndex).map((el, index) => (
      <tr key={index} className="text-white font-bold text-[20px]">
        <td className="p-4">{el.name}</td> 
        <td className="p-4 text-center">{el.brand}</td>
        <td className="p-4 text-center">{el.type}</td>
        <td className="p-4 text-center">
          <span onClick={() => toggleModal(el)}>
            <Button text={"Acessar"} buttonType={"defaultButton"} />
          </span>
        </td>
      </tr>
    ));
  };
  
  
  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages()));
  };

  const totalPages = () => {
    const filteredRelatorios = relatorios.filter((el) => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearchTerm = (
        el.name.toLowerCase().includes(searchLower) || 
        el.brand.toLowerCase().includes(searchLower) ||
        el.type.toLowerCase().includes(searchLower)
      );
      const matchesType = selectedType ? el.type === selectedType : true;
      const matchesBrand = selectedBrand ? el.brand === selectedBrand : true;

      return matchesSearchTerm && matchesType && matchesBrand;
    });
    return Math.ceil(filteredRelatorios.length / itemsPerPage);
  };
  
  return (
    <section className="flex h-[100vh]">
      <Sidebar currentAppPage={"/relatorios"} />
      <div className="w-[100%]">
        <h1 className="ml-10 mt-10 mb-5 text-[36px] text-[#545353] font-semibold">
          Relatórios
        </h1>

        <div className="flex gap-4 mb-4 ml-10">
          <span className="ml-100 mt-11 mb-5 text-[18px] text-[#545353] font-semibold">Filtros: </span>
          <Input placeholder='Pesquisar...' type='text' filter={true} value={searchTerm} onChange={handleSearchChange} />
          
          <select value={selectedType} onChange={handleTypeChange} className="ml-10 mt-10 mb-5 bg-[#94a296] p-1 text-white rounded-md">
            <option value="">Todos os Tipos</option>
            {getUniqueValues("type").map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select value={selectedBrand} onChange={handleBrandChange} className="ml-10 mt-10 mb-5 bg-[#94a296] p-1 text-white rounded-md">
            <option value="">Todas as Marcas</option>
            {getUniqueValues("brand").map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </div>

        <div className="bg-[#cdd5ce] p-7 w-[95%] mx-10 mb-8 rounded-3xl relative">
          {isLoading ? (
            <p className="ml-100 mt-10 mb-5 text-[18px] text-[#545353] font-semibold">Carregando dados...</p> 
          ) : error ? (
            <p className="ml-100 mt-10 mb-5 text-[18px] text-[#545353] font-semibold">Erro ao carregar dados: {error.message}</p>
          ) : (
            <table className="w-[100%]">
              <thead>
                <tr className="flex-1 text-center text-[16px] font-semibold text-[#545353]">
                  <th className="flex-[0_0_80%] p-4">TÍTULO</th>
                  <th className="flex-[0_0_10%] p-4 text-center">MARCA</th>
                  <th className="flex-[0_0_10%] p-4 text-center ">TIPO</th>
                  <th className="flex-grow p-4 text-center">ACESSAR</th>
                </tr>
              </thead>
              <tbody className="bg-[#94a296] p-10 gap-6 rounded-3xl ">
                {renderItems()}
              </tbody>
            </table>
          )}
          {totalPages() > 1 && (
            <div className="bg-[#94a296] pt-1 pb-1 gap-6">
              <div className="flex justify-end gap-10 p-2 mt-8 text-[20px] font-semibold text-white ">
                <span onClick={goToPreviousPage}>
                  <Button
                    text={"Anterior"}
                    buttonType={
                      currentPage !== 1 ? "listButton" : "disabledButton"
                    }
                  />
                </span>
                <span>
                  Página{" "}
                  <span className="bg-[#6fd799] p-2 rounded-2xl">
                    {currentPage}
                  </span>{" "}
                  de {totalPages()}
                </span>
                <span onClick={goToNextPage}>
                  <Button
                    text={"Próxima"}
                    buttonType={
                      currentPage !== totalPages()
                        ? "listButton"
                        : "disabledButton"
                    }
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        content={
          <CustomIframe
            title={selectedItem.name} 
            url={selectedItem.link} 
            width={1920}
            height={"86%"}
          />
        }
        onClose={() => setIsOpen(false)}
      />
    </section>
  );
}
