import CustomIframe from "../components/CustomIframe";
import CardHeader from "../components/card/CardHeader";
import Sidebar from "../layout/sidebar/Sidebar";
import Button from "../components/Button"
import Badge from "../components/Badge";
import DashboardPreview from '../components/dashboardPreview'
import { useState, useEffect } from "react";
import SidebarItemList from "../layout/sidebar/SidebarList";
import { Link } from "react-router-dom";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase/firebaseConfig'

export default function Dashboards() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIframe, setSelectedIframe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [error, setError] = useState(null); // Adiciona estado de erro

  const toggleModal = (iframe) => {
    setSelectedIframe(iframe);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const buscarDashboards = async () => {
      try {
        const response = await fetch("https://script.google.com/macros/s/AKfycbw1q47m2LRCoOSM5VsliwsuOfiPbBoA8vPddbHbe8qb0gOj72HRpVzeZHgFxkzFmjC3/exec");

        if (!response.ok) {
          throw new Error(`Erro HTTP! status: ${response.status}`);
        }

        const data = await response.json();
        setDashboards(data);
        console.log(data); // Exibe os dados recebidos no console
      } catch (error) {
        console.error("Erro ao buscar dados do Sheets:", error);
        setError(error);
      } finally {
        setIsLoading(false); // Garantir que o loading seja sempre removido
      }
    };

    buscarDashboards();
  }, []);

  const SidebarDash = ({ currentAppPage }) => {
    const [isOpen, setIsOpen] = useState(true);
  
    useEffect(() => {
      const handleResize = () => {
        setIsOpen(window.innerWidth >= 1300 ? true : false);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
  
    // eslint-disable-next-line
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    // eslint-disable-next-line
    const [userData, setUserData] = useState({})
  
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (result) => {
        if (result) {
  
          const {displayName, email} = result
          setUserData({ displayName, email })
  
          setIsLoggedIn(true)
        } else {
          setIsLoggedIn(false)
        }
  
      })
  
      return () => unsubscribe();
    },[])
  
    const Logout = () => {
      signOut(auth).then(() => {
        // Sign-out successful.
        setUserData({})
        setIsLoggedIn(false)
        sessionStorage.clear()
      }).catch((error) => {
        // An error happened.
        console.log({ error });
      });
    }
  
    return (
      <aside
        className={`bg-[#cdd5ce] ${
          isOpen ? "fixed w-[400px] h-1300px" : "fixed w-[130px] h-1300px"
        } relative transition-all `}
      >
        <div className="text-center mt-[75px]">
          <Link to="/home">
            {isOpen && (
              <img
                className="m-auto hover:scale-110 transition-all"
                width={100}
                src={`${process.env.PUBLIC_URL}/assets/Images/OpellaLogo.png`}
                alt="Logo da Opella"
              />
            )}
            {!isOpen && (
              <img
                className="m-auto"
                width={100}
                src={`${process.env.PUBLIC_URL}/assets/Images/logoMini.png`}
                alt="Logo Reduzido da Monks"
              />
            )}
          </Link>
          <p
            className={`mt-[10px] text-[21px] text-[#545353] font-semibold text-center ${
              isOpen ? "" : "hidden"
            }`}
          >
            {userData.displayName}
          </p>
        </div>
        <div>
          <ul
            className={`flex flex-col gap-[80px] text-center mt-[100px] mb-[10%] ml-[20%] ${
              isOpen ? "" : "hidden"
            }`}
          >
            <SidebarItemList
              text={"Início"}
              iconPath={"./assets/Icons/home.png"}
              redirectPage={"/home"}
              currentPage={currentAppPage}
            />
            <SidebarItemList
              text={"Dashboards"}
              iconPath={"./assets/Icons/category.png"}
              redirectPage={"/dashboards"}
              currentPage={currentAppPage}
            />
            <SidebarItemList
              text={"Status"}
              iconPath={"./assets/Icons/up.png"}
              redirectPage={"/status"}
              currentPage={currentAppPage}
            />
            <SidebarItemList
              text={"Perfil"}
              iconPath={"./assets/Icons/person.png"}
              redirectPage={"/perfil"}
              currentPage={currentAppPage}
            />
            <SidebarItemList
              text={"Relatórios"}
              iconPath={"./assets/Icons/add.png"}
              redirectPage={"/relatorios"}
              currentPage={currentAppPage}
            />
            <SidebarItemList
            text={"Sair"}
            iconPath={"./assets/Icons/logout.png"}
            redirectPage={"/"}
            currentPage={currentAppPage}
            onClick={Logout}
          />
  
            {/* TODO: SETA EMBAIXO */}
            {/* <li>
          <img
            onClick={toggleSidebar}
            alt="Seta indicando a função de recolher a barra lateral."
            className={`${
              !isOpen ? "transform rotate-180 right-10 top-2" : "right-5 bottom-10 "
            } w-[40px] h-[60px] transition-all absolute cursor-pointer`}
            src="./assets/Icons/seta.png"
          ></img>
        </li> */}
          </ul>
        </div>
        <img
          onClick={toggleSidebar}
          alt="Seta indicando a função de recolher a barra lateral."
          className={`${
            !isOpen ? "transform rotate-180 right-10 top-2" : "right-5 top-0 "
          } w-[40px] h-[60px] transition-all absolute cursor-pointer`}
          src="./assets/Icons/seta.png"
        ></img>
      </aside>
    );
  }

  return (
    <section className="flex h-[100%]">
      <SidebarDash currentAppPage={"/dashboards"} />
      <div className="bg-[#cdd5ce] p-9 w-[100%] h-[100%] my-10 mx-10 rounded-3xl">
        <CardHeader />
        <div className="bg-[#f7efe6] relative rounded-2xl p-[15px] flex flex-wrap gap-6">
          <div className="bg-white w-[100%] m-[24px] rounded-xl">
            <img
              className="m-auto w-[200px] absolute top-0 right-10"
              src={`${process.env.PUBLIC_URL}/assets/Images/LogoMediaMonks.png`}
              alt="Logo da Monks"
            />
            <img
              className="m-auto my-10"
              src={`${process.env.PUBLIC_URL}/assets/Images/OpellaLogo.png`}
              alt="Logo Opella"
            />
            <span className="bg-[#042b0b] p-[3px] rounded-md text-center m-auto w-[90%] mb-6 block"></span>
            <div className="flex flex-wrap justify-left gap-10 mx-10 border-[3px] px-5 rounded-lg pb-5 pt-5 mb-10 border-[#ddb6e5] ">
              {isLoading ? <span>Loading ...</span> : 
                dashboards.map(el => {
                  return (
                    <div>
                      <div className="mb-3 align-center">
                        <DashboardPreview
                          title={el.sanofiRetail}
                          image={el.backgroundUrl}
                          url={el.lookerUrl}
                        />
                      </div>
                      <div className="flex justify-evenly">
                        <span onClick={() => toggleModal(el)}>
                          <Button text={"Ampliar"} buttonType={'listButton'}/>
                        </span>
                        <Badge badgeType={"Normal"}/>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-75 flex items-center justify-center">
          <div className="relative p-8 bg-white w-[90%] h-[90%] mx-auto rounded-lg">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-0 right-0 m-4 text-lg text-gray-500 hover:text-red-800 focus:outline-none"
            >
              Fechar
            </button>
            <div
              className="overflow-hidden rounded-lg w-[100%] h-[100%]"

            >
              <CustomIframe
                title={selectedIframe.sanofiRetail}
                url={selectedIframe.iframeUrl}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
