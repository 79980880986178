import React, { useState, useEffect } from "react";
import Sidebar from "../layout/sidebar/Sidebar";
import InlineStyleButton from "../components/InlineStyleButton";
import Button from "../components/Button";
import Input from '../components/Input';

export default function Status() {
  const [homeList, setHomeList] = useState([]);
  const [filteredHomeList, setFilteredHomeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState("Todas");
  const [searchTerm, setSearchTerm] = useState("");

  const priorityOrder = {
    'Em produção': 1,
    'Disponível': 2,
    'Em manutenção': 3,
  };

  // Função para a de seleção de marca
  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  // Função para o campo de pesquisa
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const url = 'https://script.googleusercontent.com/macros/echo?user_content_key=7aIOGr1YAcakKxTkEcgP872v97gw7DutaF1ELDma3BRWJvcWOrMCiMYFGrpLbyNIp_nd0KMH66hc3blLs3XhTml5n_eoT_MBm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnBGXD1dAmjacH57W5U23pcsFZXaT0SeUdm52-9pzyPavX28GsJWen9fzc9bKV-2XoNh5aXXmnAw7KsOve2RE0SdNkRBltMu9aNz9Jw9Md8uu&lib=MuYoOpZLzS9Pdz8doHj9j2PbN-ZPZ-_08';

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Ordena os dados pela prioridade
        data.sort((a, b) => {
          return priorityOrder[a.status] - priorityOrder[b.status];
        });
        setHomeList(data); // Armazena a lista original
        setFilteredHomeList(data); // Armazena a lista que será filtrada
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar ou analisar dados:", error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  // useEffect para filtrar os dados que serão renderizados (com ambos filtros de marca e pesquisa)
  useEffect(() => {
    const filteredByBrand =
      selectedBrand === "Todas"
        ? homeList
        : homeList.filter((item) => item.brand === selectedBrand);

    const finalFilteredList = filteredByBrand.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredHomeList(finalFilteredList);
  }, [selectedBrand, searchTerm, homeList]);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredHomeList.length / itemsPerPage);


  const renderItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredHomeList.length);
    return (
      <>
        {filteredHomeList.slice(startIndex, endIndex).map((el, index) => (
          <tr key={index} className="text-white font-bold text-[20px]">
            <td className="p-4">{el.name}</td>
            <td className="p-4">
              <InlineStyleButton status={el.status} />
            </td>
            <td className="p-4">{el.objectives}</td>
            <td className="p-4">{el.brand}</td>
          </tr>
        ))}
      </>
    );
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Crie um conjunto (Set) para armazenar as marcas únicas
  const uniqueBrands = new Set(homeList.map((item) => item.brand));



  return (
    <section className="flex h-[100%]">
      <Sidebar currentAppPage={"/status"} />
      <div className="w-[100%] relative h-[100%]">
        <h1 className="ml-10 mt-10 mb-5 text-[36px] text-[#545353] font-semibold">
          Status de Dashboards
        </h1>
        <div className="flex gap-4 mb-4 ml-10">
          <span className="ml-100 mt-11 mb-5 text-[18px] text-[#545353] font-semibold">
            Filtros:
          </span>
          <Input placeholder='Pesquisar...' type='text' filter={true} value={searchTerm} onChange={handleSearchChange}/>

          <select
            id="brandFilter"
            value={selectedBrand}
            onChange={handleBrandChange}
            className="ml-10 mt-10 mb-5 bg-[#94a296] p-1 text-white rounded-md"
          >
            
            <option value="Todas">Todas</option>
            {[...uniqueBrands].map((brand) => (
              <option key={brand} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-[#cdd5ce] p-9 w-[95%] ml-10 mb-10 rounded-3xl relative">

          {/* Sua tabela */}
          {isLoading ? (
            <p className="ml-100 mt-10 mb-5 text-[18px] text-[#545353] font-semibold">Carregando dados...</p>
          ) : error ? (
            <p className="ml-100 mt-10 mb-5 text-[18px] text-[#545353] font-semibold">Erro ao carregar dados: {error.message}</p>
          ) : (
            <table className="w-[100%]">
              <thead>
                <tr className="text-left md:text-center w-[100%] text-[16px] font-semibold text-[#545353] w-1/2">
                  <th className="w-[50%]">DASHBOARD</th>
                  <th className="w-[25%]">STATUS</th>
                  <th className="w-[15%]">OBJETIVOS</th>
                  <th className="w-[15%]">MARCA</th>
                </tr>
              </thead>
              <tbody className="bg-[#94a296] p-10 gap-6 rounded-3xl">
                {renderItems()}
              </tbody>
              <tfoot className="bg-[#94a296] p-10 gap-6 rounded-3xl">
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-end gap-10 mt-8 p-2 text-[20px] font-semibold text-white">
                      {totalPages > 1 && (
                        <>
                          <span onClick={goToPreviousPage}>
                            {currentPage !== 1 && (
                              <Button
                                text={"Anterior"}
                                buttonType={
                                  currentPage !== 1
                                    ? "listButton"
                                    : "disabledButton"
                                }
                              />
                            )}
                          </span>
                          <span>
                            Página{" "}
                            <span className="bg-[#6fd799] p-2 rounded-2xl">
                              {currentPage}
                            </span>{" "}
                            de {totalPages}
                          </span>
                          <span onClick={goToNextPage}>
                            <Button
                              text={"Próxima"}
                              buttonType={
                                currentPage !== totalPages
                                  ? "listButton"
                                  : "disabledButton"
                              }
                            />
                          </span>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>
    </section>
  );
}